export default `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<sbml 
  xmlns="http://www.sbml.org/sbml/level2/version4" 
  xmlns:xhtml="http://www.w3.org/1999/xhtml" 
  level="2" version="4"
  >
  <model>

  </model>
</sbml>
`;